import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { createPinia } from 'pinia'
import {themeSetup} from './utils/index';
import { auth } from '@/services/firebase'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueApexCharts from "vue3-apexcharts";

// Emitter.
import mitt from 'mitt';
const emitter = mitt();

library.add(fas, fab)

// styles
import './assets/css/tailwind.css'

import i18n from './i18n'

let app: any;

auth.onAuthStateChanged((user) => {
    if (!app) {
        app = createApp(App);
        app.config.globalProperties.emitter = emitter;
        app.use(i18n).use(createPinia()).use(router).use(VueApexCharts).component('font-awesome-icon', FontAwesomeIcon).mount('#app')
    }
});
themeSetup(); // setup theme.
