export default {
  "app_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replay Dashboard"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hallo i18n !!"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche"])},
  "errors": {
    "login": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoppla..."])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsche Anmeldedaten. Bitte versuche es erneut."])}
    }
  },
  "buttons": {
    "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorherige"])}
  },
  "dashboard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamt"])},
    "games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiele"])},
    "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzungen"])},
    "patients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patienten"])},
    "creation_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellungsdatum"])},
    "last_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuletzt aktiv"])},
    "number_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl Sitzungen"])},
    "played": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gespielt"])},
    "games_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiele/Tag"])},
    "session_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzung/Tag"])},
    "games_day2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiele pro Tag"])},
    "session_day2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzung pro Tag"])},
    "exercises": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übungen"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ergebnis(se)"])},
    "calendartitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro Tag gespielte Spiele"])},
    "calendartitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktive Benutzer pro Tag"])},
    "play_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spielzeit"])}
  },
  "Mon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mo"])},
  "Tue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Di"])},
  "Wed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi"])},
  "Thu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do"])},
  "Fri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fr"])},
  "Sat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sa"])},
  "Sun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So"])},
  "January": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Januar"])},
  "February": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Februar"])},
  "March": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["März"])},
  "April": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
  "May": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mai"])},
  "June": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juni"])},
  "July": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juli"])},
  "August": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
  "September": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
  "October": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oktober"])},
  "November": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
  "December": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dezember"])},
  "A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "K": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K"])},
  "M": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["M"])},
  "B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B"])},
  "no_record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leider wurde kein Datensatz gefunden."])}
}