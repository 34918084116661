import { defineStore } from 'pinia'

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    user: JSON.parse(localStorage.getItem('user') || '{}'),
    token: localStorage.getItem('accessToken') || null
  }),
  getters: {
    isAuthenticated: (state: any) => !!state.user,
    getUser: (state: any) => state.user,
    getToken: (state: any) => state.token
  },
  actions: {
    setUser(user: string) {
      this.user = user
    },
    setToken(token: any) {
      this.token = token
    },
    logout() {
      this.user = null
      this.token = null
      localStorage.removeItem('user')
      localStorage.removeItem('accessToken')
    }
  }
})
