export default {
  "app_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replay Dashboard"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["salut i18n !!"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche"])},
  "errors": {
    "login": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oups..."])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauvaises informations de connexion. Veuillez réessayer."])}
    }
  },
  "buttons": {
    "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
    "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précédent"])}
  },
  "dashboard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de bord"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeux"])},
    "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessions"])},
    "patients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patients"])},
    "creation_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création"])},
    "last_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière activité"])},
    "number_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de sessions"])},
    "played": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joué(s)"])},
    "games_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeux/Jour"])},
    "session_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessions/Jour"])},
    "games_day2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeux par jour"])},
    "session_day2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session par jour"])},
    "exercises": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exercices"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["résultat(s)"])},
    "calendartitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeux joués par jour"])},
    "calendartitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs actifs par jour"])},
    "play_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps de jeu"])}
  },
  "Mon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lun"])},
  "Tue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mar"])},
  "Wed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mer"])},
  "Thu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeu"])},
  "Fri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ven"])},
  "Sat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sam"])},
  "Sun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dim"])},
  "January": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Janvier"])},
  "February": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Février"])},
  "March": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mars"])},
  "April": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avril"])},
  "May": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mai"])},
  "June": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juin"])},
  "July": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juillet"])},
  "August": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Août"])},
  "September": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Septembre"])},
  "October": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Octobre"])},
  "November": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novembre"])},
  "December": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décembre"])},
  "A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "K": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K"])},
  "M": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["M"])},
  "B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B"])},
  "no_record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, aucun résultat n'a été trouvé."])}
}