<template>
  <div :class="appTheme" class="bg-secondary">
    <div
      class="flex flex-col h-screen dark:text-white light-text-color bg-inherit dark:bg-gray-700"
    >
      <div class="" ref="main">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script lang="js">
import ThemeChanger from "@/components/ThemeChanger";

import { getDoc, doc  } from "firebase/firestore"; 
import { firestore } from "@/services/firebase.ts";

import {
    useThemeStore
} from '@/store/ThemeStore';
import {
    useAuthStore
} from '@/store/auth.ts'
import { auth } from '@/services/firebase.ts'

export default {
    name: 'app',
    components: {
        // ThemeChanger,
    },
    watch: {
        async $route(to, from) {
            const base = `Portal`
            let title = ""
            if (to.path !== "/") this.path = '/'
            else this.path = ""

            if (typeof to.meta.title === 'string')
                title = `${to.meta.title} - ${base}`
            else if (typeof to.meta.title === 'function')
                title = `${to.meta.title(to)} - ${base}`
            else title = base
            document.title = title

            if (!auth.currentUser) {
              if (to.path.includes('/admin')) {
                this.$router.push('/')
              }
          }
        },
    },
    data() {
        return {
            path: "",
            scroll: 0,
            isLogin: false,
            authStore: useAuthStore(),
            user: null,
        }
    },
    async created() {
        // back to top button handler.
        window.addEventListener('scroll', this.handleScroll);
        await this.preload();
    },
    async mounted() {
      await this.preload();
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    computed: {
        appTheme() {
            return useThemeStore().theme;
        },
    },
    methods: {
        async preload() {
            this.login = this.authStore.getToken !== null;
          // on change state of login in store.
          if (!this.login) {
            await auth.signOut();
          }
          if (this.login) {
            this.user = auth.currentUser;
            this.authStore.setUser(this.user)
            this.authStore.setToken(localStorage.getItem('accessToken'));
          }
        },
        async logout() {
            this.mobileMenuOpen = false;
            this.user = null;
            this.login = false;
            await auth.signOut();
            await this.authStore.logout()
            this.$router.push('/')
        },
        scrollTop() {
            window.scrollTo(0, 0);
        },
    },
}
</script>
