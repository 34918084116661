export default {
  "app_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replay Dashboard"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "errors": {
    "login": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops..."])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bad credentials Please try again."])}
    }
  },
  "buttons": {
    "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])}
  },
  "dashboard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Games"])},
    "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessions"])},
    "patients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patients"])},
    "creation_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation Date"])},
    "last_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Active"])},
    "number_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number Sessions"])},
    "played": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Played"])},
    "games_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Games/Day"])},
    "session_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session/Day"])},
    "games_day2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Games Per Day"])},
    "session_day2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session Per Day"])},
    "exercises": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exercises"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["result(s)"])},
    "calendartitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Games Played Per day"])},
    "calendartitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Users Per day"])},
    "play_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time played"])}
  },
  "Mon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon"])},
  "Tue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tue"])},
  "Wed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wed"])},
  "Thu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thu"])},
  "Fri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fri"])},
  "Sat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sat"])},
  "Sun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sun"])},
  "January": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January"])},
  "February": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February"])},
  "March": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March"])},
  "April": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
  "May": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
  "June": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June"])},
  "July": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["July"])},
  "August": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
  "September": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
  "October": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October"])},
  "November": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
  "December": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December"])},
  "A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "K": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K"])},
  "M": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["M"])},
  "B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B"])},
  "no_record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, No record found."])}
}